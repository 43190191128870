<template>
  <div class="income-container">
    <div class="income_search">
      <a-button class="search_item" type="primary" @click="handleClickAdd(1)">创建收入信息</a-button>
      <div>
        <a-select allowClear class="search_item" v-model="partyType" placeholder="请选择账户类型" style="width: 150px">
          <a-select-option value="1"> 广告主 </a-select-option>
          <a-select-option value="2"> 代理商 </a-select-option>
          <a-select-option value="3"> 广告平台</a-select-option>
          <a-select-option value="4" v-if="role === 'SUPER_ADMIN' || (adminUserId != null && adminUserId == 1)">
            平台媒体
          </a-select-option>
        </a-select>
        <a-select
          allowClear
          class="search_item"
          v-model="incomeModel"
          placeholder="请选择收款方式"
          style="width: 150px"
        >
          <a-select-option value="1"> 预付费 </a-select-option>
          <a-select-option value="2"> 后付费 </a-select-option>
        </a-select>
        <a-input
          allowClear
          class="search_item"
          v-model.trim="partyName"
          autocomplete="off"
          placeholder="请输入直客名称"
          style="width: 150px"
        />
        <a-button class="search_item" type="primary" @click="handleSearch">搜索</a-button>
      </div>
    </div>
    <div class="income_table">
      <a-table
        class="m_table_cust"
        :loading="loading"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        :scroll="{ x: 1500 }"
        :rowKey="
          (record, index) => {
            return index
          }
        "
        bordered
      >
        <div slot="accounts">直客</div>
        <template slot="action" slot-scope="text, record">
          <a-button
            size="small"
            v-if="record.approveSts !== '1'"
            @click="handleClickUpdatePlace(record)"
          >修改</a-button
          >
          <a-button
            size="small"
            v-if="record.approveSts === '1' && record.incomeSts === '0' && record.incomeModel !== '1'"
            @click="accountAdd(record)"
          >到账</a-button
          >
        </template>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="query.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        :pageSize="query.limit"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
      />
      <m-empty style="min-height: 80vh" v-if="dataList.length === 0">
        <template #description> 暂无数据 </template>
      </m-empty>
    </div>
    <NewIncome
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="this.advertisers"
      @changePlace="changePlace"
    />
    <Account
      v-if="accountVisible"
      :placeInfos="placeInfos"
      :visible="accountVisible"
      @modalCancel="accountVisible = false"
      @changePlace="changePlace"
    />
  </div>
</template>

<script>
import { incomeListPage, advertiserList } from '@/api/financialManagementA'
import NewIncome from '../components/newIncome'
import Account from '../components/account'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'

// import mixDate from '@/mixins/initDate'
const columns = [
  {
    dataIndex: 'addTime',
    title: '日期',
    key: 'addTime',
    align: 'left',
    fixed: true,
    width: 180
  },
  {
    dataIndex: 'partyName',
    title: '公司名称',
    key: 'partyName',
    align: 'left',
    fixed: true,
    width: 200
  },
  {
    dataIndex: 'partyType',
    title: '账户类型',
    key: 'partyType',
    width: 120,
    align: 'left',
    customRender: (text, record, index) => {
      if (record.partyType === '1') {
        text = '广告主'
      } else if (record.partyType === '2') {
        text = '代理商'
      } else if (record.partyType === '3') {
        text = '广告平台'
      } else if (record.partyType === '4') {
        text = '平台媒体'
      }
      return text
    }
  },
  {
    dataIndex: 'incomeAmount',
    title: '收入金额',
    key: 'incomeAmount',
    width: 120,
    align: 'left',
    customRender: (text, record, index) => {
      text = record.incomeAmount || record.incomeAmount === 0 ? record.incomeAmount : '-'
      return numFormat(text, 3, '')
    }
  },
  {
    dataIndex: 'accountNo',
    title: '账号',
    key: 'accountNo',
    width: 180,
    align: 'left',
    customRender: (text, record, index) => {
      return record.accountNo || '-'
    }
  },
  {
    dataIndex: 'agentName',
    title: '代理公司',
    key: 'agentName',
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username',
    width: 120,
    align: 'left',
    customRender: (text, record, index) => {
      return record.username || '--'
    }
  },
  {
    dataIndex: 'incomeModel',
    title: '收款方式',
    key: 'incomeModel',
    align: 'left',
    width: 120,
    customRender: (text, record, index) => {
      if (record.incomeModel === '1') {
        text = '预付费'
      } else if (record.incomeModel === '2') {
        text = '后付费'
      }
      return text
    }
  },
  {
    dataIndex: 'incomeSts',
    title: '结算状态',
    key: 'incomeSts',
    align: 'left',
    width: 120,
    customRender: (text, record, index) => {
      if (record.incomeSts === '0') {
        text = '未到账'
      } else if (record.incomeSts === '1') {
        text = '已到账'
      }
      return text
    }
  },
  {
    dataIndex: 'sjAmount',
    title: '到账金额',
    key: 'sjAmount',
    width: 120,
    align: 'left',
    customRender: (text, record, index) => {
      text = record.sjAmount || record.sjAmount === 0 ? record.sjAmount : '-'
      return numFormat(text, 3, '')
    }
  },
  {
    dataIndex: 'sjTime',
    title: '到账时间',
    key: 'sjTime',
    align: 'left',
    width: 190,
    customRender: (text, record, index) => {
      return record.sjTime || '-'
    }
  },
  {
    dataIndex: 'approveSts',
    title: '审核状态',
    key: 'approveSts',
    align: 'left',
    width: 120,
    customRender: (text, record, index) => {
      if (record.approveSts === '0') {
        text = '待审核'
      } else if (record.approveSts === '1') {
        text = '审核通过'
      } else if (record.approveSts === '-1') {
        text = '审核不通过'
      }
      return text || '-'
    }
  },
  {
    dataIndex: 'approveRemark',
    title: '审核意见',
    key: 'approveRemark',
    width: 120,
    align: 'left',
    customRender: (text, record, index) => {
      return record.approveRemark || '-'
    }
  },
  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    width: 160,
    align: 'left'
  }
]
export default {
  name: 'Income',
  components: { NewIncome, Account },
  data () {
    return {
      partyType: undefined,
      incomeModel: undefined,
      partyName: '',
      contractType: undefined,
      contractNo: '',
      advertisers: '',
      columns,
      addPlaceVisible: false,
      accountVisible: false,
      placeInfo: {},
      placeInfos: {},
      loading: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      appList: [],
      placeList: [],
      industry: [],
      currentGroup: [],
      groupList: [],
      mode: 'inline',
      theme: 'light',
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      BusNames: { label: 'name', value: 'id' },
      pid: [],
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      businessUser: [],
      dataList: [],
      keys: ''
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId
    })
  },
  mounted () {
    this.getincomeListPage()
    this.getadvertiserList()
  },
  methods: {
    handleClick (e) {
      this.keys = e.key
      this.getincomeListPage()
    },
    async getincomeListPage () {
      this.loading = true
      this.dataList = []
      const res = await incomeListPage(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },
    async getadvertiserList () {
      this.loading = true
      this.dataList = []
      const res = await advertiserList(this.query)
      if (res.code === 200) {
        this.businessUser = res.data
      }
    },
    // 新增广告主
    handleClickAdd (type) {
      this.advertisers = 1
      this.addPlaceVisible = true
    },
    handleSearch () {
      this.query = {
        page: 1,
        limit: 10,
        partyType: this.partyType || '',
        incomeModel: this.incomeModel || '',
        partyName: this.partyName || ''
      }
      this.timer = setTimeout(() => {
        this.getincomeListPage()
      }, 1000)
    },
    // 修改
    handleClickUpdatePlace (record) {
      this.advertisers = 2
      this.placeInfo = record
      this.addPlaceVisible = true
    },
    // 到账
    accountAdd (record) {
      this.placeInfos = record
      this.accountVisible = true
    },
    changePlace () {
      this.getincomeListPage()
    },

    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getincomeListPage()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getincomeListPage()
      }, 1000)
    }
  }
}
</script>

<style lang="less">
.income-container {
  padding: 0 10px;
  .income_search {
    border-radius: 5px 0;
    padding: 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    .search_item {
      margin-right: 10px;
    }
  }
  .income_table {
    border-radius: 0 5px;
    padding: 0 20px 20px;
    min-height: 80vh;
    background: #fff;
    .pagination {
      margin-top: 20px;
    }
  }
}
</style>
