<template>
  <a-modal v-model="accountVisible" :title="this.names" @onCancel="handleCancel" @ok="handleSubmit">
    <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
      <a-form-model-item has-feedback label="公司名称">
        {{ this.partyNamePush }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="账户类型">
        {{ this.namesType }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="实际到账金额" prop="sjAmount">
        <a-input v-model.trim="query.sjAmount" autocomplete="off" placeholder="请输入实际到账金额" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { doIncome } from '@/api/financialManagementA'
import { mapState } from 'vuex'
// import {agentListAdd} from '@/api/system'
export default {
  data () {
    return {
      partyNamePush: '',
      namesType: '',
      advertiserLists: [],
      agentLists: [],
      invoic: {},
      valse: '',
      accountNames: '',
      accountBanks: '',
      accountCodes: '',
      registerAddresss: '',
      taxNumbers: '',
      taxPhones: '',
      imagesPush: [],
      contractTypes: '',
      agentUser: [],
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      rules: {
        sjAmount: [{ required: true, message: '请输入实际到账金额', trigger: 'blur' }]
      },
      appList: [],
      positionList: [],
      templateList: [],
      names: '',
      valPush: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Number,
      default: 0
    },
    appId: {
      default: '',
      type: String
    },
    placeInfos: {
      default: () => ({}),
      type: Object
    },
    supplier: {
      type: Number,
      default: 0
    }
  },
  watch: {
    placeInfos: {
      handler (val) {
        this.query.id = val.id
        this.partyNamePush = val.partyName
        if (val.partyType === '1') {
          this.namesType = '广告主'
        } else if (val.partyType === '2') {
          this.namesType = '代理商'
        } else if (val.partyType === '3') {
          this.namesType = '广告平台'
        }
        this.names = '到账操作'
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {
    // this.getTaxInfoList()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
      // roleId: (state) => state.user.roleId,
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    accountVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.accountVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resp = await doIncome(this.query)
          if (resp.code === 200) {
            this.$message.success('添加成功!')
            this.$emit('changeAppInfo', 'add')
          }
          this.query = {}
          this.$emit('changePlace')
          this.accountVisible = false
        }
      })
    }
  }
}
</script>

<style lang="less"></style>
