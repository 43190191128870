<template>
  <div>
    <a-modal
      v-model="addPlaceVisible"
      :title="this.names"
      @onCancel="handleCancel"
      @ok="handleSubmit"
      dialogClass="batch_advertiser_modal"
    >
      <div class="add_advertiser_wrapper">
        <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
          <a-form-model-item has-feedback label="账户类型" prop="partyType">
            <a-select
              v-model="query.partyType"
              @change="onContract"
              placeholder="请选择账户类型"
              v-if="this.isAdd === 1 || this.isAdd === 2"
            >
              <a-select-option value="1"> 广告主 </a-select-option>
              <a-select-option value="2"> 代理商 </a-select-option>
              <a-select-option value="3"> 广告平台 </a-select-option>
              <a-select-option value="4"> 平台媒体 </a-select-option>
            </a-select>
            <a-select
              v-model="query.partyType"
              @change="onContract"
              placeholder="请选择账户类型"
              v-if="this.isAdd === 3"
              disabled
            >
              <a-select-option value="1"> 广告主 </a-select-option>
              <a-select-option value="2"> 代理商 </a-select-option>
              <a-select-option value="3"> 广告平台 </a-select-option>
              <a-select-option value="4"> 平台媒体 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item has-feedback label="收入来源" prop="partyId" v-if="this.valse === '1'">
            <a-select
              v-model="query.partyId"
              placeholder="请选中收入来源"
              v-if="this.isAdd === 1 || this.isAdd === 2"
              @change="advertiser"
            >
              <a-select-option v-for="item in this.advertiserLists" :key="item.id">
                {{ item.advertiserName }}
              </a-select-option>
            </a-select>
            <a-select v-model="query.partyId" placeholder="请选中收入来源" v-if="this.isAdd === 3" disabled>
              <a-select-option v-for="item in this.advertiserLists" :key="item.id">
                {{ item.advertiserName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item has-feedback label="收入来源" prop="partyId" v-if="this.valse === '2'">
            <a-select
              v-model="query.partyId"
              placeholder="请选中收入来源"
              v-if="this.isAdd === 1 || this.isAdd === 2"
              @change="advertiser"
            >
              <a-select-option v-for="item in this.agentLists" :key="item.id">
                {{ item.agentName }}
              </a-select-option>
            </a-select>
            <a-select v-model="query.partyId" placeholder="请选中收入来源" v-if="this.isAdd === 3" disabled>
              <a-select-option v-for="item in this.agentLists" :key="item.id">
                {{ item.agentName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item has-feedback label="收入金额" prop="incomeAmount">
            <a-input
              v-model.trim="query.incomeAmount"
              autocomplete="off"
              placeholder="请输入收入金额"
              v-if="this.isAdd === 1 || this.isAdd === 2"
            />
            <a-input
              v-model.trim="query.incomeAmount"
              autocomplete="off"
              placeholder="请输入收入金额"
              v-if="this.isAdd === 3"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="收入模式" prop="incomeType">
            <a-select
              v-model="query.incomeType"
              placeholder="请选择收入模式"
              v-if="this.isAdd === 1 || this.isAdd === 2"
            >
              <a-select-option value="1"> 直客收入 </a-select-option>
              <a-select-option value="2"> 平台收入 </a-select-option>
              <a-select-option value="3"> 媒体联盟收入 </a-select-option>
            </a-select>
            <a-select v-model="query.incomeType" placeholder="请选择收入模式" v-if="this.isAdd === 3" disabled>
              <a-select-option value="1"> 直客收入 </a-select-option>
              <a-select-option value="2"> 平台收入 </a-select-option>
              <a-select-option value="3"> 媒体联盟收入 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item has-feedback label="收款方式" prop="incomeModel">
            <a-select
              v-model="query.incomeModel"
              placeholder="请选择收款方式"
              @change="onContractPush"
              v-if="this.isAdd === 1 || this.isAdd === 2"
            >
              <a-select-option value="1"> 预付费 </a-select-option>
              <a-select-option value="2"> 后付费 </a-select-option>
            </a-select>
            <a-select
              v-model="query.incomeModel"
              placeholder="请选择收款方式"
              @change="onContractPush"
              v-if="this.isAdd === 3"
              disabled
            >
              <a-select-option value="1"> 预付费 </a-select-option>
              <a-select-option value="2"> 后付费 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item has-feedback label="收入状态" prop="incomeSts">
            <a-select v-model="query.incomeSts" placeholder="请选择收入状态">
              <a-select-option value="0"> 未到账 </a-select-option>
              <a-select-option value="1"> 已到账 </a-select-option>
            </a-select>
          </a-form-model-item>
          <template v-if="isAdd === 2 || isAdd === 3">
            <a-form-model-item has-feedback label="付款凭证" prop="file" v-if="valse === '4'">
              <div class="creative_item_wrapper">
                <div class="el_image_box" v-if="query.fileUrl">
                  <el-image
                    class="elImage"
                    style="height: 128px; max-width: var(--ad--creative--width)"
                    :src="resourceUrl + query.fileUrl"
                    :preview-src-list="[resourceUrl + query.fileUrl]"
                  >
                  </el-image>
                  <div class="modal">
                    <a-icon
                      class="deleteIcon"
                      type="eye"
                      @click="() => handlePreviewImg(resourceUrl + query.fileUrl)"
                    />
                  </div>
                </div>
              </div>
            </a-form-model-item>
          </template>
          <a-form-model-item has-feedback label="审核意见" v-if="this.isAdd === 3">
            <a-input v-model.trim="query.approveRemark" autocomplete="off" type="textarea" placeholder="请输入审核意见" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer" v-if="this.isAdd === 3">
        <a-button @click="handleCancel">拒绝</a-button>
        <a-button @click="handleSubmit" type="primary">同意</a-button>
      </template>
    </a-modal>
    <a-modal dialogClass="img_modal" :visible="previewVisibleModel" :footer="null" @cancel="handleCanPreview">
      <img alt="example" style="width: 100%; max-height: 600px" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import {
  incomeAdd,
  incomeUpdate,
  advertiserList, // 广告主
  agentList, // 代理
  agree, // 审核同意
  refuse // 审核拒绝
} from '@/api/financialManagementA'
import { mapState } from 'vuex'
// import {agentListAdd} from '@/api/system'
export default {
  data () {
    return {
      partyIdPush: '',
      advertiserLists: [],
      agentLists: [],
      invoic: {},
      valse: '',
      addPlaceVisibles: false,
      accountNames: '',
      accountBanks: '',
      accountCodes: '',
      registerAddresss: '',
      taxNumbers: '',
      taxPhones: '',
      imagesPush: [],
      contractTypes: '',
      agentUser: [],
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      rules: {
        partyType: [{ required: true, message: '请选择账户类型', trigger: 'change' }],
        partyId: [{ required: true, message: '请输入收入来源', trigger: 'change' }],
        incomeAmount: [{ required: true, message: '请输入收入金额', trigger: 'blur' }],
        incomeType: [{ required: true, message: '请选择收入模式', trigger: 'change' }],
        incomeModel: [{ required: true, message: '请选择收款方式', trigger: 'change' }],
        incomeSts: [{ required: true, message: '请选择收入状态', trigger: 'change' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      appList: [],
      positionList: [],
      templateList: [],
      names: '',
      previewVisibleModel: false,
      previewImage: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Number,
      default: 0
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    supplier: {
      type: Number,
      default: 0
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        if (this.isAdd === 1) {
          this.names = '收入添加'
        } else if (this.isAdd === 2) {
          this.names = '修改添加'
          this.valse = val.partyType
          this.partyIdPush = val.partyId * 1
          this.query = JSON.parse(JSON.stringify(val))
        } else if (this.isAdd === 3) {
          this.names = '收入审核'
          this.valse = val.partyType
          this.partyIdPush = val.partyId * 1
          this.query = JSON.parse(JSON.stringify(val))
        }
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (this.partyIdPush !== '') {
      this.query.partyId = this.partyIdPush
    }
    this.getAdvertiserList()
    this.getAgentList()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
      // roleId: (state) => state.user.roleId,
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 点击图片取消
    handleCanPreview () {
      this.previewVisibleModel = false
    },
    // 点击图片
    handlePreviewImg (src) {
      this.previewVisibleModel = true
      this.previewImage = src
    },
    onContractPush (val) {
      if (val === '1') {
        this.query.incomeSts = '1'
      } else if (val === '2') {
        this.query.incomeSts = '0'
      }
    },
    advertiser (val) {
      this.$forceUpdate()
    },
    onContract (val) {
      this.valse = val
      if (this.valse === '1') {
        this.getAdvertiserList()
      } else if (this.valse === '2') {
        this.getAgentList()
      }
    },
    // 广告主
    async getAdvertiserList () {
      const res = await advertiserList()
      if (res.code === 200) {
        this.advertiserLists = res.data
      }
    },
    // 代理
    async getAgentList () {
      const res = await agentList()
      if (res.code === 200) {
        this.agentLists = res.data
      }
    },

    changeUser (val) {},
    handleCancel () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            if (this.isAdd === 3) {
              this.query = {
                incomeSts: this.query.incomeSts,
                approveRemark: this.query.approveRemark,
                id: this.query.id
              }
              const resp = await refuse(this.query)
              if (resp.code === 200) {
                this.$message.success('拒绝成功!')
                this.$emit('changeAppInfo', 'add')
              }
            }
            this.query = {}
            this.$emit('changePlace')
            this.addPlaceVisible = false
          } else {
            return false
          }
        }
      })

      // this.isAdd = ''
      this.addPlaceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            if (this.isAdd === 1) {
              if (this.valse === '3') {
                this.query.partyId = 0
                const resp = await incomeAdd(this.query)
                if (resp.code === 200) {
                  this.$message.success('添加成功!')
                  this.$emit('changeAppInfo', 'add')
                }
              } else {
                const resp = await incomeAdd(this.query)
                if (resp.code === 200) {
                  this.$message.success('添加成功!')
                  this.$emit('changeAppInfo', 'add')
                }
              }
            } else if (this.isAdd === 2) {
              if (this.valse === '3') {
                this.query.partyId = 0
                const resp = await incomeUpdate(this.query)
                if (resp.code === 200) {
                  this.$message.success('修改成功!')
                  this.$emit('changeAppInfo', 'add')
                }
              } else {
                const resp = await incomeUpdate(this.query)
                if (resp.code === 200) {
                  this.$message.success('修改成功!')
                  this.$emit('changeAppInfo', 'add')
                }
              }
            } else if (this.isAdd === 3) {
              this.query = {
                incomeSts: this.query.incomeSts,
                approveRemark: this.query.approveRemark,
                id: this.query.id
              }
              const resp = await agree(this.query)
              if (resp.code === 200) {
                this.$message.success('审核成功!')
                this.$emit('changeAppInfo', 'add')
              }
            }
            this.query = {}
            this.$emit('changePlace')
            this.addPlaceVisible = false
          } else {
            return false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.add_advertiser_wrapper {
  background: #fff;
  border-radius: 5px;
  padding: 20px;
}
.creative_item_wrapper {
  width: 270px;
  height: 130px;
  .noneImg {
    width: 200px;
    height: 100px;
    background: #fafafa;
  }
  .el_image_box {
    background: #fafafa;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .modal {
        opacity: 1;
      }
    }
    .modal {
      opacity: 0;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 130px;
      .deleteIcon {
        margin: 5px;
        cursor: pointer;
        color: #333;
        font-size: 20px;
        &:hover {
          color: @primary-color;
        }
      }
    }
  }
}
::v-deep {
  .img_modal {
    top: 33%;
  }
}
</style>
